import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { deleteAxios, getAxios, postAxios } from '../../axios/Axios';
import request from '../../request/request';
import { useLocation, useNavigate } from 'react-router-dom';
import Constants from '../constants/Constants';
import { ConfirmDialog } from 'primereact/confirmdialog';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { Calendar } from 'primereact/calendar';
import Loader from '../loader/Loader';

import { formatDate, getCroppedImg, getValidArray } from '../../utils/helper';
import ImageCropper from '../ImageCropper/ImageCropper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const UpdateProductCategory = ({ changeToast }) => {
	const [categoryIconPreview, setCategoryIconPreview] = useState(null);
	const [existingBanners, setExistingBanners] = useState([]);
	const [newBanners, setNewBanners] = useState([]);
	const [existingTile, setExistingTile] = useState([]);
	const [newTile, setNewTile] = useState();
	const [blankTile, setBlankTile] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [selectedParentCategory, setSelectedParentCategory] = useState([]);
	const [loading, setLoading] = useState(false);
	const categoryIconInputRef = useRef(null);
	const bannerInputRef = useRef(null);
	const tileInputRef = useRef(null);
	const secondaryIconRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const productCATID = location.state;
	const [data, setData] = useState();
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [completedCrop, setCompletedCrop] = useState(null);
	const [src, setSrc] = useState(null);
	const [blankBanner, setBlankBanner] = useState([]);
	const [bannerType, setBannerType] = useState({
		type: '',
		width: 0,
		height: 0,
	});
	const [secondaryIcon, setSecondaryIcon] = useState({
		blankIcon: null,
		existingIcon: null,
		newIcon: null,
	});

	const [categoryClassification, setCategoryClassification] = useState();
	const [businessType, setBusinesstype] = useState({
		classification_group_id: [],
	});
	const globalCategoryDetails = [
		{
			label: 'CATEGORY NAME',
			name: data?.category_name,
			ID: data?.product_category_id,
		},

		{
			label: 'PARENT CATEGORY NAME',
			name: data?.parent_category_name,
			ID: data?.primary_parent_category_id,
		},

		{
			label: 'DESCRIPTION',
			name: data?.description,
		},
	];

	const options = [
		{
			value: `${data?.primary_parent_category_id}`,
			label: `${data?.parent_category_name}(${data?.primary_parent_category_id})`,
		},
	];

	const [description, setDescription] = useState();
	const maxLength = 255;
	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const response = await getAxios(`${request.globalCategoryList}`, {
				params: {
					more_details: 1,
					product_category_id: productCATID,
				},
			});

			const catResponse = await getAxios(
				`${request.categoryClassification}?parent_type_id=EXTERNAL`
			);

			if (catResponse?.data?.apiresponse?.type === 'OK') {
				setCategoryClassification(catResponse?.data?.records);
			}

			if (response?.data.apiresponse?.type === 'OK') {
				setData(response?.data?.record[0]);
			}

			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [productCATID]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleCategoryIconChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setCategoryIconPreview(URL.createObjectURL(file));
		} else {
			setCategoryIconPreview(null);
		}
	};

	const handleBannerChange = (e, type, width, height) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				setBannerType({
					type: type,
					width: width,
					height: height,
				});
				setSrc(reader.result?.toString() || '');
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const handleCloseCropper = () => {
		console.log('Clicked');
		if (!src) return;
		bannerInputRef.current.value = '';
		setSrc(null);
	};

	const generateCroppedImage = async () => {
		if (!completedCrop || !src) return;
		try {
			const croppedImageBlob = await getCroppedImg(
				src,
				completedCrop,
				bannerType
			);
			const croppedImageFile = new File(
				[croppedImageBlob],
				'cropped-image.jpg',
				{
					type: croppedImageBlob.type || 'image/jpeg',
				}
			);

			if (bannerType?.type === 'hero') {
				setNewBanners(() => [
					// ...prev,
					{
						preview: URL.createObjectURL(croppedImageFile),
						file: croppedImageFile,
					},
				]);

				setBlankBanner([]);
			} else {
				setNewTile(() => [
					{
						preview: URL.createObjectURL(croppedImageFile),
						file: croppedImageFile,
					},
				]);
				setBlankTile([]);
			}

			setSrc(null);
		} catch (error) {
			console.error('Error generating cropped image:', error);
		}
	};
	const handleCheckboxChange = (e) => {
		if (e.target.checked !== isChecked) {
			if (e.target.checked) {
				setIsChecked(true);
				setConfirmVisible(false);
			} else {
				setConfirmVisible(true);
			}
		}
	};

	const accept = () => {
		setIsChecked(false);
		setConfirmVisible(false);
	};

	const reject = () => {
		setConfirmVisible(false);
	};

	useEffect(() => {
		// Check if data?.classifications exists and set state accordingly
		if (data?.classifications) {
			const classificationIds = data.classifications.map(
				(classification) => classification.classification_group_id
			);

			// Set the state to include the classification_group_id array
			setBusinesstype({
				classification_group_id: classificationIds,
			});
		}
	}, [data?.classifications]);

	console.log('This is Cat Class', businessType);

	const handleUpdateData = useCallback(
		async (e) => {
			e.preventDefault();
			setLoading(true);
			const formData = new FormData(e.target);
			formData.append('category_id', data?.product_category_id);
			if (categoryIconInputRef.current.files.length > 0) {
				const categoryIcon = categoryIconInputRef.current.files[0];
				formData.append('icon', categoryIcon);
			}
			formData.append('extra_data', JSON.stringify(businessType));
			if (newBanners?.length > 0) {
				newBanners.forEach((banner) => {
					if (banner.file && banner.file instanceof File) {
						formData.append('banners', banner.file);
					}
				});
			}

			if (newTile?.length > 0) {
				newTile.forEach((tile) => {
					formData.append('tilebanners', tile.file);
				});
			}
			if (secondaryIcon?.newIcon && secondaryIcon.newIcon.length > 0) {
				formData.append('secondary_icon', secondaryIcon.newIcon[0]);
			}

			formData.set('enabled', isChecked ? 'Y' : 'N');
			formData.append(
				'parent_category_id',
				selectedParentCategory?.value || ''
			);

			try {
				const response = await postAxios(
					request.updatePoductCategory,
					formData
				);
				if (response?.data?.apiresponse?.type === 'OK') {
					changeToast(
						Constants.TOAST_SUCCESS,
						Constants.TOAST_SUCCESS_TITLE,
						response?.data?.apiresponse?.message
					);
					setNewBanners([]);
					setNewTile([]);
					setBlankTile([]);
					setBlankBanner([]);
					await fetchData();
				} else {
					changeToast(
						Constants.TOAST_ERROR,
						Constants.TOAST_ERROR_TITLE,
						response?.data?.apiresponse?.message
					);
				}

				setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		},
		[
			data,
			isChecked,
			selectedParentCategory,
			newBanners,
			newTile,
			secondaryIcon?.newIcon,
			businessType,
		]
	);

	useEffect(() => {
		if (!data) return;
		const categoryIcon = data?.category_contents?.find(
			(content) => content?.content_type_id === 'CATEGORY_ICON'
		);
		const categoryBanner = data?.category_contents?.filter(
			(content) => content?.content_type_id === 'CATEGORY_BANNER'
		);

		const categoryTile = data?.category_contents?.filter(
			(content) => content?.content_type_id === 'CATEGORY_TILEBANNER'
		);

		const secondaryCategoryIcon = data?.category_contents?.filter(
			(content) => content?.content_type_id === 'SECONDARY_ICON'
		);

		if (data?.enabled === 'Y') {
			setIsChecked(true);
		}

		if (data?.primary_parent_category_id) {
			setSelectedParentCategory(options);
		}
		setCategoryIconPreview(categoryIcon || '/images/no-icon.webp');

		if (categoryBanner && categoryBanner?.length > 0) {
			setExistingBanners(categoryBanner);
		} else if (categoryBanner?.length === 0) {
			setBlankBanner(['/images/no-image.webp']);
		}
		if (categoryTile && categoryTile?.length > 0) {
			setExistingTile(categoryTile);
		} else {
			setBlankTile(['/images/tile-no-image.webp']);
		}
		if (secondaryCategoryIcon && secondaryCategoryIcon?.length > 0) {
			setSecondaryIcon({
				blankIcon: null,
				existingIcon: secondaryCategoryIcon,
				newIcon: null,
			});
		} else {
			setSecondaryIcon({
				blankIcon: '/images/no-icon.webp',
				existingIcon: null,
				newIcon: null,
			});
		}
	}, [data]);

	const loadOptions = async (inputValue, callback) => {
		if (inputValue.length < 3) {
			callback([]);
			return;
		}
		try {
			const response = await getAxios(`${request.globalCategoryList}`, {
				params: { term: inputValue },
			});
			const options = response.data.record.map((item) => ({
				label: `${item?.category_name}(${item?.product_category_id})`,
				value: item?.product_category_id,
			}));
			callback(options);
		} catch (error) {
			console.error('Error fetching data:', error);
			callback([]);
		}
	};

	const handleDeleteBanner = useCallback(
		async (index = null, type, content_id) => {
			try {
				if (type === 'new') {
					setNewBanners((prev) => prev.filter((_, i) => i !== index));
					if (newBanners?.length === 0 && existingBanners?.length === 0) {
						setBlankBanner(['/images/no-image.webp']);
						bannerInputRef.current.value = '';
					}
				}
				if (type === 'newIcon') {
					setCategoryIconPreview(null);
					if (categoryIconInputRef.current) {
						categoryIconInputRef.current.value = '';
					}
					setCategoryIconPreview('/images/no-icon.webp');
				}

				if (type === 'newTile') {
					setNewTile((prev) => prev.filter((_, i) => i !== index));
					if (newTile?.length === 0 || existingTile?.length === 0) {
						setBlankTile(['/images/tile-no-image.webp']);
						tileInputRef.current.value = '';
					}
				}

				if (type === 'secondary_icon') {
					console.log('This Block Runs');
					setSecondaryIcon({
						blankIcon: '/images/no-icon.webp',
						existingIcon: null,
						newIcon: null,
					});
					secondaryIconRef.current.value = '';
				}

				if (!content_id) {
					return;
				}
				const response = await deleteAxios(request.deleteContentCategory, {
					params: {
						content_id: content_id,
						category_id: data?.product_category_id,
					},
				});
				if (response?.data?.apiresponse?.type === 'OK') {
					if (type === 'banner' && index !== null) {
						setExistingBanners((prev) => prev.filter((_, i) => i !== index));
						if (bannerInputRef.current) {
							bannerInputRef.current.value = '';
						}
					} else if (type === 'icon') {
						setCategoryIconPreview(null);
						if (categoryIconInputRef.current) {
							categoryIconInputRef.current.value = '';
						}
					} else {
						setExistingTile(null);
						if (tileInputRef.current) {
							tileInputRef.current.value = '';
						}

						setSecondaryIcon({
							blankIcon: null,
							existingIcon: null,
							newIcon: null,
						});
						secondaryIconRef.current.value = '';
					}
					changeToast(
						Constants.TOAST_SUCCESS,
						Constants.TOAST_SUCCESS_TITLE,
						response?.data?.apiresponse?.message
					);
					fetchData();
				}
			} catch (error) {
				console.log(error);
			}
		},
		[data]
	);

	return (
		<>
			<div className="w-100 d-flex flex-row gap-5">
				<div className="update-category-details-container  d-flex flex-column gap-0 p-4">
					<>
						{loading && <Loader />}
						{globalCategoryDetails.map((detail, index) => (
							<div
								key={index}
								className="update-category-detail d-flex flex-column"
							>
								<span>{detail.label}</span>
								{detail.label === 'DESCRIPTION' ? (
									<span className="fw-normal">{detail.name}</span>
								) : (
									<span className="">{detail.name}</span>
								)}
								{detail.ID && (
									<span className="">
										<strong>ID:</strong> {detail.ID}
									</span>
								)}
							</div>
						))}
						<div className="d-flex gap-4">
							<div>
								<span className="icon-class">PRIMARY ICON: </span>
								<div className="  category-preview ">
									{categoryIconPreview && (
										<div className="d-flex flex-column">
											<div className="mt-2 position-relative category-preview-container">
												<img
													src={
														categoryIconPreview?.object_info?.small
															?.public_url ||
														categoryIconPreview ||
														'/images/no-icon.webp'
													}
													alt="Category Icon Preview"
													style={{ width: '100px', height: '100px' }}
													className="p-1 rounded"
												/>

												{categoryIconPreview != '/images/no-icon.webp' && (
													<Button
														type="button"
														className="position-absolute category-preview-iconBtn"
														onClick={() => {
															console.log('Clicked');
															const isBlobUrl =
																typeof categoryIconPreview === 'string' &&
																categoryIconPreview.startsWith('blob:');
															if (isBlobUrl) {
																handleDeleteBanner(
																	null,
																	'newIcon',
																	categoryIconPreview?.content_id
																);
															} else {
																handleDeleteBanner(
																	null,
																	'icon',
																	categoryIconPreview?.content_id
																);
															}
														}}
														icon="pi pi-times"
														aria-label="Cancel"
														rounded
														severity="danger"
														text
														raised
													/>
												)}
											</div>
											{/* <p className='icon-class'>{categoryIconPreview?.content_id}</p> */}
										</div>
									)}
								</div>
							</div>

							<div>
								<span className="icon-class">SECONDARY ICON: </span>
								<div className="  category-preview ">
									{secondaryIcon && (
										<div className="d-flex flex-column">
											<div className="mt-2 position-relative category-preview-container">
												<img
													src={
														(getValidArray(secondaryIcon?.existingIcon) &&
															secondaryIcon?.existingIcon[0]?.object_info?.small
																?.public_url) ||
														(secondaryIcon?.newIcon &&
															URL.createObjectURL(secondaryIcon?.newIcon[0])) ||
														secondaryIcon?.blankIcon ||
														'/images/no-icon.webp'
													}
													alt="Category Icon Preview"
													style={{ width: '100px', height: '100px' }}
													className="p-1 rounded"
												/>

												{getValidArray(secondaryIcon?.existingIcon) ||
												secondaryIcon?.newIcon !== null ? (
													<Button
														type="button"
														className="position-absolute category-preview-iconBtn"
														onClick={() => {
															if (getValidArray(secondaryIcon?.newIcon)) {
																handleDeleteBanner(null, 'secondary_icon');
															} else if (
																secondaryIcon?.existingIcon !== null &&
																secondaryIcon?.existingIcon.length > 0
															) {
																handleDeleteBanner(
																	null,
																	'icon',
																	secondaryIcon?.existingIcon[0]?.content_id
																);
															}
														}}
														icon="pi pi-times"
														aria-label="Cancel"
														rounded
														severity="danger"
														text
														raised
													/>
												) : null}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>

						<span className="icon-class">HERO BANNER : </span>
						<div className="mb-2  gap-2 banner-category ">
							{existingBanners?.map((fileURL, index) => (
								<div className="">
									<div
										key={index}
										className="position-relative banner-category-container "
									>
										<img
											src={fileURL?.object_info?.small?.public_url}
											alt={`Banner Preview ${index}`}
											style={{ width: '100%' }}
											className=" p-1 rounded"
										/>

										<Button
											className="position-absolute category-preview-bannerBtn"
											type="button"
											icon="pi pi-times"
											rounded
											text
											severity="danger"
											onClick={() =>
												handleDeleteBanner(index, 'banner', fileURL?.content_id)
											}
											aria-label="Cancel"
										/>
									</div>
									{/* <p className='icon-class'>{fileURL?.content_id}</p> */}
								</div>
							))}
							{newBanners?.length > 0
								? newBanners.map((fileURL, index) => (
										<div
											key={index}
											className="position-relative banner-category-container "
										>
											<img
												src={fileURL?.preview}
												alt={`Banner Preview ${index}`}
												style={{ width: '100%' }}
												className="p-1 rounded"
											/>
											{fileURL?.preview && (
												<Button
													className="position-absolute category-preview-bannerBtn "
													type="button"
													icon="pi pi-times"
													rounded
													text
													severity="danger"
													onClick={() => handleDeleteBanner(index, 'new')}
													aria-label="Cancel"
												/>
											)}
										</div>
									))
								: blankBanner.map((image, index) => (
										<div
											key={index}
											className="position-relative banner-category-container "
										>
											<img
												src={image}
												alt={`Blank Banner ${index}`}
												style={{ width: '100%' }}
												className="p-1 rounded"
											/>
										</div>
									))}
						</div>

						<span className="icon-class">TILE BANNER : </span>
						<div className="mb-2  gap-2 banner-category ">
							{existingTile?.map((fileURL, index) => (
								<div className="">
									<div
										key={index}
										className="position-relative banner-category-tileBanner"
									>
										<img
											src={fileURL?.object_info?.small?.public_url}
											alt={`Banner Preview ${index}`}
											style={{ width: '100%' }}
											className=" p-1 rounded"
										/>

										<Button
											className="position-absolute category-preview-tileBtn"
											type="button"
											icon="pi pi-times"
											rounded
											text
											severity="danger"
											onClick={() =>
												handleDeleteBanner(index, 'tile', fileURL?.content_id)
											}
											aria-label="Cancel"
										/>
									</div>
								</div>
							))}
							{newTile?.length > 0
								? newTile.map((fileURL, index) => (
										<div>
											<div
												key={index}
												className="position-relative banner-category-newTileBanner"
											>
												<img
													src={fileURL?.preview}
													alt={`Banner Preview ${index}`}
													className="p-1 rounded"
												/>
												{fileURL?.preview && (
													<Button
														className="position-absolute category-preview-newTileBtn "
														type="button"
														icon="pi pi-times"
														rounded
														text
														severity="danger"
														onClick={() => handleDeleteBanner(index, 'newTile')}
														aria-label="Cancel"
													/>
												)}
											</div>
										</div>
									))
								: blankTile.map((image, index) => (
										<div
											key={index}
											className="position-relative banner-category-blankTileBanner "
										>
											<img
												src={image}
												alt={`Blank Banner ${index}`}
												style={{ width: '40%' }}
												className="p-1 rounded"
											/>
										</div>
									))}
						</div>
					</>
				</div>

				<div className=" card update-category-form d-flex align-items-center align-self-start">
					<ConfirmDialog
						visible={confirmVisible}
						onHide={() => setConfirmVisible(false)}
						message="Are you sure you want to change the status Doing this will unlist your category?"
						header="Confirmation"
						icon="pi pi-exclamation-triangle"
						accept={accept}
						reject={reject}
					/>

					<div className=" w-100 d-flex justify-content-center p-4 m-auto">
						<div className="card-body">
							<form onSubmit={handleUpdateData}>
								<div className=" mb-3">
									<label htmlFor="" className="form-label form-required">
										Name
									</label>

									<input
										type="text"
										className="form-control"
										placeholder="Name"
										name="category_name"
										required
										defaultValue={data?.category_name || ''}
									/>
								</div>
								<div className=" mb-3">
									<label htmlFor="" className="form-label">
										Description
									</label>
									<span
										style={{
											fontSize: '12px',
											color: description?.length >= maxLength ? 'red' : '#999',
										}}
									>
										{' '}
										(The maximum character limit for description is 255)
									</span>
									<textarea
										type="text"
										className="form-control"
										name="description"
										placeholder="Description"
										onChange={(e) => setDescription(e.target.value)}
										defaultValue={data?.description || ''}
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="" className="form-label">
										Parent Category ID
										<span
											style={{
												color: 'red',
												fontSize: '12px',
												fontWeight: 'bold',
											}}
										>
											{' '}
											(Use with caution)
										</span>
									</label>

									<AsyncSelect
										cacheOptions
										loadOptions={loadOptions}
										defaultOptions
										onChange={setSelectedParentCategory}
										value={selectedParentCategory}
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="formFile" className="">
										Upload Primary Icon
									</label>

									<span
										style={{
											color: '#999',
											fontSize: '12px',
										}}
									>
										{' '}
										(Only Svg files supported with icon size of 512px/512px)
									</span>
									<br></br>
									{categoryIconPreview?.object_info?.small?.public_url && (
										<span
											style={{
												color: 'red',
												marginBottom: '5px',
											}}
											className="icon-delete"
										>
											Kindly delete previous category icon before uploading new
											one
										</span>
									)}
									<input
										className={`form-control ${categoryIconPreview?.object_info?.small?.public_url ? 'categoryIcon-disabled' : ''}`}
										type="file"
										id="formFile"
										ref={categoryIconInputRef}
										onChange={handleCategoryIconChange}
										disabled={
											categoryIconPreview?.object_info?.small?.public_url
										}
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="formFile" className="">
										Upload Secondary Icon
									</label>

									<span
										style={{
											color: '#999',
											fontSize: '12px',
										}}
									>
										{' '}
										(Only Svg files supported with icon size of 512px/512px)
									</span>
									<br></br>
									{getValidArray(secondaryIcon?.existingIcon) && (
										<span
											style={{ color: 'red', marginBottom: '5px' }}
											className="icon-delete"
										>
											Kindly delete previous category icon before uploading new
											one
										</span>
									)}

									{/* )} */}
									<input
										className={`form-control ${categoryIconPreview?.object_info?.small?.public_url ? 'categoryIcon-disabled' : ''}`}
										type="file"
										id="formFile"
										onChange={(e) => {
											const files = Array.from(e.target.files);
											if (files.length > 0) {
												setSecondaryIcon({
													blankIcon: null,
													existingIcon: null,
													newIcon: files,
												});
											} else {
												setSecondaryIcon({
													blankIcon: null,
													existingIcon: null,
													newIcon: [],
												});
											}
										}}
										ref={secondaryIconRef}
										disabled={getValidArray(secondaryIcon?.existingIcon)}
									/>
								</div>

								<div className="mb-3">
									<label htmlFor="formFile " className="form-label ">
										Upload Hero Banner
									</label>

									<span
										style={{
											color: '#999',
											fontSize: '12px',
										}}
									>
										{' '}
										(Only JPEG, PNG, and WebP files supported with banner size
										of 1950px/400px)
									</span>
									<br></br>
									{existingBanners?.length > 0 && (
										<span
											style={{
												color: 'red',
												marginBottom: '5px',
											}}
											className="icon-delete"
										>
											Kindly delete previous hero banner before uploading new
											one
										</span>
									)}
									<input
										className={`form-control ${existingBanners?.length > 0 ? 'categoryIcon-disabled' : ''}`}
										type="file"
										id="formFile"
										ref={bannerInputRef}
										onChange={(e) => {
											handleBannerChange(e, 'hero', 1940, 400);
										}}
										accept="image/*"
										disabled={existingBanners?.length}
									/>
								</div>

								<div className="mb-3">
									<label htmlFor="formFile " className="form-label ">
										Upload Tile Banner
									</label>

									<span
										style={{
											color: '#999',
											fontSize: '12px',
										}}
									>
										{' '}
										(Only JPEG, PNG, and WebP files supported with banner size
										of 475px/350px)
									</span>
									<br></br>
									{existingTile?.length > 0 && (
										<span
											style={{
												color: 'red',
												marginBottom: '5px',
											}}
											className="icon-delete"
										>
											Kindly delete previous tile banner before uploading new
											one
										</span>
									)}
									<input
										className={`form-control ${existingTile?.length > 0 ? 'categoryIcon-disabled' : ''}`}
										type="file"
										id="formFile"
										ref={tileInputRef}
										onChange={(e) => {
											handleBannerChange(e, 'tile', 475, 350);
										}}
										accept="image/*"
										disabled={existingTile?.length}
									/>
								</div>
								<div className="form-check mb-4">
									<input
										className="form-check-input"
										type="checkbox"
										name="enabled"
										// value="Y"
										id="flexCheckDefault"
										// defaultChecked={data?.enabled === 'Y'}
										checked={isChecked}
										onChange={handleCheckboxChange}
									/>
									<label
										className="form-check-label"
										htmlFor="flexCheckDefault"
									>
										Enable
									</label>
								</div>
								<div className="d-flex flex-row gap-7 align-items-center ">
									{categoryClassification?.map((item, index) => {
										const isChecked =
											businessType.classification_group_id.includes(
												item?.classification_group_id
											);
                                            

											const imgSrc = item?.classification_group_id === 'B2B'
											? '/images/briefcase.png' 
											: item?.classification_group_id === 'B2C'
											? '/images/box.png'       
											: null; 


										return (
											<div key={index} className="form-check d-flex align-items-center  mb-4">
												<input
													className="form-check-input"
													type="checkbox"
													checked={isChecked}
													
													onChange={(e) => {
														if (e.target.checked) {
															setBusinesstype((prev) => ({
																...prev,
																classification_group_id: [
																	...prev.classification_group_id,
																	item?.classification_group_id,
																],
															}));
														} else {
															setBusinesstype((prev) => ({
																...prev,
																classification_group_id:
																	prev.classification_group_id.filter(
																		(id) => id !== item?.classification_group_id
																	),
															}));
														}
													}}
												/>
												 
												<label
													className="form-check-label d-flex gap-2"
												>
													 {imgSrc && <img src={imgSrc} width={24} alt={item?.classification_group_id} className="ml-1" />}
													{item?.description} ({item?.classification_group_id}
													)
												</label>
											</div>
										);
									})}
								</div>

								<div className="d-flex prime-react-btn w-100 align-items-center gap-2 ">
									<button
										className="btn w-100 text-white"
										style={{ backgroundColor: 'grey' }}
										onClick={() => {
											navigate('/global-category-list');
										}}
									>
										Go Back
									</button>
									<button
										type="submit"
										className="btn submit-btn w-100 text-white"
									>
										{loading ? 'Updating...' : 'Update'}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<>
				<ImageCropper
					setCrop={setCrop}
					crop={crop}
					src={src}
					setCompletedCrop={setCompletedCrop}
					zoom={zoom}
					setZoom={setZoom}
					bannerType={bannerType}
					generateCroppedImage={generateCroppedImage}
					handleCloseCropper={handleCloseCropper}
				/>
			</>
		</>
	);
};
