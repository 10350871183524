import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoMdRefresh } from 'react-icons/io';

import { PiDotsThreeOutlineVerticalBold } from 'react-icons/pi';

import { FaPlus } from 'react-icons/fa';
import { MdOutlineResetTv } from 'react-icons/md';
import { Tooltip } from 'primereact/tooltip';

import { TieredMenu } from 'primereact/tieredmenu';
import { getAxios } from '../../axios/Axios';
import request from '../../request/request';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../loader/Loader';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toTitleCase, useDebounce } from '../../utils/helper';

const GlobalProductList = () => {
	const [globalProductList, setGlobalProductList] = useState();
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(50);
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedParentCategory, setSelectedParentCategory] = useState([]);

	const navigate = useNavigate();

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const response = await getAxios(`${request.getProtalProducts}`, {
				params: {
					page: currentPage,
					rows,
					query: searchText,
					portal_category_id: selectedParentCategory?.value,
				},
			});
			if (response?.data.apiresponse?.type === 'OK') {
				setGlobalProductList(response?.data);
			}

			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [currentPage, rows, searchText, selectedParentCategory]);

	useEffect(() => {
		fetchData();
	}, [first, currentPage, rows]);

	const handleSearch = useCallback(() => {
		fetchData();
	}, [searchText, selectedParentCategory]);

	const handleResetFilter = useCallback(() => {
		setSearchText('');
		setSelectedParentCategory([]);

		if (selectedParentCategory.length === 0 || !searchText) {
			fetchData();
		}
	}, []);

	const onPageChange = (event) => {
		setFirst(event.first);
		setCurrentPage(event.page + 1);
		setRows(event.rows);
	};

	const loadOptions = useDebounce(async (inputValue, callback) => {
		if (inputValue.length < 3) {
			callback([]);
			return;
		}
		try {
			const response = await getAxios(`${request.globalCategoryList}`, {
				params: { term: inputValue },
			});
			const options = response.data.record.map((item) => ({
				label: `${item?.category_name}`,
				value: item?.product_category_id,
			}));
			callback(options);
		} catch (error) {
			console.error('Error fetching data:', error);
			callback([]);
		}
	}, 1000);

	const renderIcon = (rowData) => {
		const productImage = rowData?.product_images?.product_small_image_url;
		return productImage ? (
			<div className="d-flex justify-content-center">
				<img src={productImage} alt="Category Icon" height={50} />
			</div>
		) : (
			<div className="d-flex justify-content-center">
				<img src="/images/no-icon.webp" alt="Category Icon" height={50} />
			</div>
		);
	};
	const renderActions = (rowData) => {
		const menu = useRef(null);
		const navigate = useNavigate();
		const items = [
			{
				label: 'Edit Product',
				icon: 'pi pi-file-edit',
				command: () => {
					navigate(`/update-product-list?id=${rowData?.product_id}`, {
						state: rowData?.product_id,
					});
				},
			},
			{
				label: 'Create Advertisement',
				icon: 'pi pi-file-edit',
				command: () => {
					navigate(`/create-product-advertisement?id=${rowData?.product_id}`, {
						state: rowData?.product_id,
					});
				},
			},
		];
		return (
			<div className=" cursor-pointer flex justify-content-center">
				<TieredMenu model={items} popup ref={menu} breakpoint="767px" />
				<span
					className="global-category-action"
					icon="pi pi-check"
					aria-label="Filter"
					label="Show"
					onClick={(e) => menu.current.toggle(e)}
				>
					<PiDotsThreeOutlineVerticalBold />
				</span>
			</div>
		);
	};

	const renderDescription = (rowData) => {
		const maxLength = 50;
		const description = rowData.product_description || '';
		return description.length > maxLength
			? `${description.substring(0, maxLength)}...`
			: description;
	};

	const renderOwnerName = (rowData) => {
		const companyName = toTitleCase(rowData?.company_name)
		return (
			<div className="webiste-detail-container ">
				<div>{companyName}</div>
				<div className="category-details-id">
					<span>Party ID : {rowData?.party_id}</span>
				</div>
			</div>
		);
	};

	return (
		<div>
			{loading && <Loader />}
			<div className="global-category-heading d-flex justify-content-between ">
				<p className="h2">Global Product List</p>
				<div
					className="global-category-heading-addBtn"
					onClick={() => {
						navigate('');
					}}
				>
					<div className="global-category-heading-btn">
						<FaPlus
							style={{
								stroke: '#fff',
								fill: '#fff',
								width: '20px',
								height: '20px',
							}}
						/>
					</div>
					<p>Add New Product</p>
				</div>
			</div>

			<div className="m-2 d-flex gap-2 justify-content-between align-items-center  w-100">
				<div className="pr-3">
					<span className="font-bold ">Total Records:</span>
					<span> {globalProductList?.metadata?.total} </span>
				</div>
				<div
					style={{ width: '75%' }}
					className="d-flex gap-2 flex-row  justify-content-end align-items-center  "
				>
					<div className="d-flex gap-2 mt-3">
						<span className="p-input-icon-left d-flex gap-2">
							<i className="pi pi-search" />
							<InputText
								type="search website"
								value={searchText}
								onInput={(e) => setSearchText(e.target.value)}
								placeholder="Search..."
								className="p-inputtext-sm md:h-3rem"
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										handleSearch();
									}
								}}
							/>
							<div className="">
								<AsyncSelect
									cacheOptions
									loadOptions={loadOptions}
									defaultOptions
									onChange={setSelectedParentCategory}
									value={selectedParentCategory}
									className=""
									styles={{
										control: (base) => ({
											...base,
											padding: '5px',
											width: '250px',
										}),
									}}
								/>
							</div>

							<Button
								variant="outline-success"
								disabled={loading}
								onClick={handleSearch}
								className="md:w-10rem"
							>
								Search
							</Button>
						</span>
					</div>

					<div className="d-flex gap-3 cursor-pointer mt-3">
						<Button
							variant=""
							disabled={loading}
							onClick={!loading ? fetchData : null}
							className="md:w-8rem d-flex gap-1 align-items-center justify-content-center refresh-btn"
						>
							<IoMdRefresh size="1.3em" />
							Refresh
						</Button>
					</div>

					<div className="d-flex gap-3 cursor-pointer mt-3">
						<span data-pr-tooltip="Reset" data-pr-position="top">
							<MdOutlineResetTv
								style={{
									stroke: '#fff',
									fill: 'green',
									width: '50px',
									height: '50px',
								}}
								onClick={handleResetFilter}
								size="1.3em"
							/>
						</span>
						<Tooltip target="[data-pr-tooltip]" />
					</div>
				</div>
			</div>

			<DataTable value={globalProductList?.records} showGridlines>
				<Column body={renderIcon} header="Product Image" />
				<Column body={renderOwnerName} header="Owner Name" />
				<Column field="product_name" header="Product Name" />
				<Column body={renderDescription} header="Description" />
				<Column field="product_type_id" header="Product ID" />
				<Column body={renderActions} header="Action" />
			</DataTable>

			<Paginator
				first={first}
				rows={rows}
				totalRecords={globalProductList?.metadata?.total}
				rowsPerPageOptions={[50, 100, 150, 200, 250]}
				onPageChange={onPageChange}
			/>
		</div>
	);
};

export default GlobalProductList;
